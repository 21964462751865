<template>
  <div class="qrCode xl:pt-12 xl:mx-10 <xl:mx-[28px] relative text-$black-color6">
    <div class="text-center">
      <CountDown></CountDown>
      <div v-if="type !== 'phone' && !info.loading && !info.qrUrl" @click="getQR" class="border border-theme-color bg-$theme-color-light-1 rounded-[26PX] w-[250PX] h-[250PX] xl:mt-15 <xl:mt-6 inline-flex flex-col flex flex-col justify-center">
        <div class="text-[130PX]">
          <van-icon name="qr"/>
        </div>
        <span class="text-$theme-color xl:text-xl">Get UPI QR</span>
      </div>
      <div v-if="info.loading">
        <div class="mb-2 xl:mt-10 <xl:mt-4 w-[250PX] h-[250PX] inline-flex items-center justify-center">
          <Loading></Loading>
        </div>
        <p class="w-full inline-block <xl:text-[16px] xl:text-base">Generating QR Code...</p>
      </div>
      <div v-if="info.qrUrl">
        <canvas class="inline-block xl:mt-4" id="qrcodeImg"></canvas>
        <p class="w-full inline-block <xl:text-[16px] xl:text-base">
          Scan the QR using any UPI app on your
          <br />
          phone like BHIM, PhonePe,Google Pay etc.
        </p>
      </div>
    </div>
    <div class="absolute w-full xl:bottom-10 <xl:bottom-0" v-if="info.qrUrl">
      <div class="xl:text-base <xl:text-[14px] text-center text-$black-color6">You can share or save the page to ask others to help pay</div>
      <div class="share flex items-center justify-center xl:mt-4 <xl:mt-[20px]">
        <!-- <div class="border border-$black-color2 rounded-[26PX] xl:w-26 xl:h-10 xl:text-sm <xl:w-[120px] <xl:h-[36px] <xl:text-[14px] flex items-center justify-center mx-1">
          <img src="../assets/icon_share.png" class="xl:w-6 xl:h-5 xl:mr-2 <xl:w-[23px] <xl:h-[23px] <xl:mr-[8px]" alt="">
          <span>Share</span>
        </div> -->
        <div @click="saveMask(true)" class="border border-$black-color2 rounded-[26PX] xl:w-26 xl:h-10 xl:text-sm <xl:w-[120px] <xl:h-[36px] <xl:text-[14px] flex items-center justify-center mx-1">
          <img src="../assets/icon_save.png" class="xl:w-5 xl:h-5 xl:mr-2 <xl:w-[20px] <xl:h-[20px] <xl:mr-[8px]" alt="">
          <span>Save</span>
        </div>
      </div>
    </div>
    <Camera :qrUrl="info.qrUrl" type="qr" @saveMask="saveMask" v-if="info.showCamera"></Camera>
    <!-- <button class="rounded-[26PX] absolute bottom-24 left-0 bg-$theme-color text-$color-fff h-19 w-full text-3xl" v-if="retryCount > 200" @click="checkStatus">
      PAY COMPLETED
    </button> -->
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { nextTick, reactive, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import qrcode from "qrcode";
import Loading from "@/components/Loading";
import CountDown from "@/components/CountDown";
import Camera from "@/components/Camera";
import { getPaymentResult, upiIntentPayment } from "@/api/payment";

let router = useRouter();
let route = useRoute();
let store = useStore();

let info = reactive({
  id: "",
  codeImg: "",
  qrUrl: false,
  loading: false,
  showCamera: false
});
//获取二维码
const getQR = async () => {
  let ops = {
    onetimeId: sessionStorage.getItem("onetimeId"),
  };
  info.loading = true;
  store.commit("updateOpsStatus", true);
  const homeImg = require('../assets/icon_home.png');
  try {
    let data = await upiIntentPayment(ops)
    info.loading = false;
    info.qrUrl = data.intentUrl || ''
    nextTick(() => {
      let qrCanvas = document.getElementById("qrcodeImg");
      qrcode.toCanvas(qrCanvas, info.qrUrl, {
        errorCorrectionLevel: "H",
        height: 250,
        width: 250,
        iconSrc: homeImg,
        curtainImg: "qrcode_border.png"
      });
      getQRResult();
    });
  } catch (error) {
    store.commit("updateOpsStatus", false);
    info.loading = false;
  }

  
};
let type = route.query.type;//phone表示手机端显示 需要自动获取
type === 'phone' && getQR();

//支付结果查询
let getResult;

const getQRResult = async () => {
  try {
    let data = await getPaymentResult({},{noAlert:true});
    if(data.status === "PENDING"){
      //未支付重试
      clearTimeout(getResult);
      getResult = setTimeout(() => {
        getQRResult();
      }, 1000);
    } else if ( data.status === "SUCCESS" || data.status === "FAILURE") {
      //已支付或支付失败 跳转结果页
      clearTimeout(getResult);
      pushResult();
    } 
  } catch (error) {
    //查询异常
    clearTimeout(getResult);
    getResult = setTimeout(() => {
      getQRResult();
    }, 1000);
  }
  
};
const saveMask = (type)=>{
  info.showCamera = type;
}
onBeforeUnmount(() => {
  clearTimeout(getResult);
});

//跳转失败页面
const pushResult = () => {
  router.replace({
    path: "/paymentResult",
    // query: {
    //   type: "QR",
    // },
  });
};
</script>

<style lang="scss" type="text/scss">

.van-loading {
  margin: 10%;
}
.van-icon{
  color: var(--theme-color);
  font-size: 1em;
}
</style>
